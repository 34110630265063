import { QRNormal } from "react-qrbtf";
import { Sheet } from "react-modal-sheet";
import { useState } from "react";
import QrCodeView from "./qrCodeView";

const CardCode = ({ cardInfo }: { cardInfo?: string | null }) => {
  const [codeExpanded, setCodeExpanded] = useState(false);
  return (
    <div className="bg-white border rounded-2xl m-1 shadow-2xl max-w-sm w-full p-1 pl-4 pr-2">
      <div
        className="flex items-center justify-between w-full"
        onClick={() => setCodeExpanded(true)}
      >
        <div className="text-xs font-semibold">Open QR code to network</div>
        {cardInfo && (
          <QRNormal
            value={"https://pass-card.app"}
            className="my-qrcode"
            styles={{ svg: { width: "80px" } }}
            type="round"
            size={72}
            opacity={100}
            posType="round"
            otherColor="#000000"
            posColor="#000000"
          />
        )}
      </div>
      <Sheet isOpen={codeExpanded} onClose={() => setCodeExpanded(false)}>
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Content className="flex flex-col items-center">
            <div className="flex justify-center items-center max-w-screen-sm space-y-6">
              <QrCodeView
                url={cardInfo}
                fullSize
                caption="Scan code for my online business card."
              />
            </div>
            <div className="flex justify-center items-center max-w-screen-sm space-y-6 pt-12">
              <p className="text-sm italic">
                <span className="font-semibold">Scan code</span> to network with
                me
              </p>
            </div>
            <footer className="fixed bottom-0 left-0 right-0 flex md:items-center md:justify-between mb-0">
              <div className="w-full">
                <div className="bg-white w-full p-4 flex justify-center">
                  <span className="text-xs text-gray-500 sm:text-center dark:text-gray-400 ">
                    Powered by{" "}
                    <span className="font-semibold text-sm">
                      <a
                        href="https://digital.pass-card.app"
                        className="hover:underline"
                      >
                        Pass
                      </a>
                    </span>
                  </span>
                </div>
              </div>
            </footer>
          </Sheet.Content>
        </Sheet.Container>

        <Sheet.Backdrop />
      </Sheet>
    </div>
  );
};

export { CardCode };
