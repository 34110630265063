import { useContext, useEffect, useState } from "react";
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
import { useAuth } from "services/authServer";
import { appDb } from "config";
import { Timestamp, doc, setDoc } from "firebase/firestore";
import { Spinner } from "./../components/loader";
import { UserContext } from "store/user/userContext";
import { Modal } from "flowbite-react";
export enum Options {
  MONTHLY = "monthly",
  YEARLY = "yearly",
  LIFETIME = "lifetime",
}

export interface Sub {
  type: Options;
  date_processed: Timestamp;
  transactionId: string;
}

const Account = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [option, setOption] = useState<Options>(Options.YEARLY);
  const { user, logout } = useAuth();
  const { sub, expiry, isExpired, loadingSub, setSub } =
    useContext(UserContext);

  const [showConfirm, setShowConfirm] = useState(false);

  useEffect(() => {
    if (sub) {
      setOption(sub.type);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sub]);

  const getPrice = (option: string): number => {
    switch (option) {
      case Options.LIFETIME:
        return 93000;
      case Options.YEARLY:
        return 16500;
      case Options.MONTHLY:
        return 1750;
      default:
        return 16500;
    }
  };

  const handlePayment = useFlutterwave({
    public_key: "FLWPUBK-e1a7130a6289db58f6cdc64297a44806-X",
    tx_ref: user?.uid || "anonymous_ref",
    amount: getPrice(option),
    currency: "RWF",
    payment_options:
      "card,mobilemoneyrwanda,ussd,mpesa,account,mobilemoneyghana,mobilemoneyfranco,mobilemoneyuganda,mobilemoneyzambia,credit",
    customer: {
      email: user?.email || "",
      phone_number: user?.phoneNumber || "",
      name: user?.displayName || "",
    },
    customizations: {
      title: "Pass by Fabriq",
      description: "Subscription",
      logo: "/logo192.png",
    },
  });

  const updateOrderPayment = (
    success: boolean,
    ref: string,
    transId: string
  ) => {
    setLoading(true);
    const subDocRef = doc(appDb, "subs", user!.uid);
    const payload = {
      type: option,
      date_processed: Timestamp.fromDate(new Date()),
      transactionId: transId,
      reference: ref,
    };

    setDoc(subDocRef, payload, { merge: true })
      .then(() => {
        setLoading(false);
        setSub(payload);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  const requestPayment = () => {
    setLoading(true);
    handlePayment({
      callback: (response) => {
        setLoading(false);
        closePaymentModal();
        updateOrderPayment(
          response.status === "successfull",
          response.flw_ref,
          `${response.transaction_id}`
        );
      },
      onClose: () => {
        setLoading(false);
      },
    });
  };

  const openShop = () => {
    //window.location.href = getUrl();
    window.open("https://www.shop.pass-card.app", "_blank");
  };

  const openUrl = (url: string) => {
    window.open(url, "_blank");
  };

  return (
    <div className="p-4 pt- gap-4 justify-center pb-32 flex flex-col items-center">
      {/* <div className="grid grid-cols-1 md:grid-cols-2 items-center content-center gap-4">
        <div className="flex flex-row items-center flex-wrap text-lg font-medium">
          Use your digital cards on the mobile app
        </div>
        <div className="flex flex-row flex-wrap gap-4 items-end justify-center">
          <img
            className="h-12 w-auto"
            src={appStore}
            alt="Download pass from the app store"
          />
          <img
            className="h-12 w-auto"
            src={playStore}
            alt="Download pass from Google play store"
          />
        </div>
      </div> */}

      {loading || loadingSub ? (
        <Spinner />
      ) : (
        <div className="flex flex-col gap-4">
          <div className="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
            <div className="text-lg font-bold tracking-tight text-gray-900 dark:text-white">
              Pass app subscription
            </div>
            <div className=" mb-4 text-sm">(From $1.29)</div>
            <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
              The world's most versatile digital business card, on your mobile
              device.
            </p>

            {!isExpired && expiry && (
              <p className="mb-3 text-xs font-normal text-gray-700 italic dark:text-gray-400">
                Your current subscription is valid until{" "}
                <span className="font-bold">{expiry.toLocaleString()}</span>.
              </p>
            )}

            {isExpired && (
              <>
                <div className="grid grid-cols-1 gap-4">
                  <div className="flex gap-2">
                    <div className="flex items-center h-5">
                      <input
                        id="yearly-option"
                        aria-describedby="helper-yearly-text"
                        type="radio"
                        value={Options.YEARLY}
                        className="w-4 h-4 text-accent bg-gray-100 border-gray-300 focus:ring-accent dark:focus:ring-accent dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        checked={option === Options.YEARLY}
                        onChange={(e) => setOption(e.target.value as Options)}
                      />
                    </div>
                    <div className="ms-2 text-sm">
                      <label className="font-medium text-gray-900 dark:text-gray-300">
                        Yearly ($34.99)
                      </label>
                      <p
                        id="helper-yearly-text"
                        className="text-xs font-normal text-gray-500 dark:text-gray-300"
                      >
                        Save 23% and skip monthly subscriptions for a year
                      </p>
                    </div>
                  </div>

                  <div className="flex gap-2">
                    <div className="flex items-center h-5">
                      <input
                        id="yearly-option"
                        aria-describedby="helper-yearly-text"
                        type="radio"
                        value={Options.MONTHLY}
                        className="w-4 h-4 text-accent bg-gray-100 border-gray-300 focus:ring-accent dark:focus:ring-accent dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        checked={option === Options.MONTHLY}
                        onChange={(e) => setOption(e.target.value as Options)}
                      />
                    </div>
                    <div className="ms-2 text-sm">
                      <label className="font-medium text-gray-900 dark:text-gray-300">
                        Monthly ($3.99)
                      </label>
                      <p
                        id="helper-yearly-text"
                        className="text-xs font-normal text-gray-500 dark:text-gray-300"
                      >
                        Best for short term usage
                      </p>
                    </div>
                  </div>

                  {/* <div className="flex gap-2">
                    <div className="flex items-center h-5">
                      <input
                        id="lifetime-option"
                        aria-describedby="helper-lifetime-text"
                        type="radio"
                        value={Options.LIFETIME}
                        className="w-4 h-4 text-emerald-600 bg-gray-100 border-gray-300 focus:ring-emerald-500 dark:focus:ring-emerald-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        checked={option === Options.LIFETIME}
                        onChange={(e) => setOption(e.target.value as Options)}
                      />
                    </div>
                    <div className="ms-2 text-sm">
                      <label className="font-medium text-gray-900 dark:text-gray-300">
                        Lifetime ($69)
                      </label>
                      <p
                        id="helper-yearly-text"
                        className="text-xs font-normal text-gray-500 dark:text-gray-300"
                      >
                        A one time payment for forever usage
                      </p>
                    </div>
                  </div> */}
                </div>

                <div className="flex flex-row justify-end mt-6">
                  <button
                    className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-black focus:ring-4 focus:outline-none bg-white border border-gray-200 rounded-lg focus:ring-background dark:bg-background dark:hover:bg-background dark:focus:ring-background gap-2 justify-center"
                    onClick={requestPayment}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Z"
                      />
                    </svg>
                    Buy subscription
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      )}

      <div className="flex flex-col gap-4">
        <div className="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
          <div className="text-lg font-bold tracking-tight text-gray-900 dark:text-white">
            Shop cards
          </div>
          <div className=" mb-4 text-sm">(From $19.5)</div>
          <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
            An NFC enabled smart business card. It is the last physical business
            card you will ever buy.
          </p>
          <p className="text-xs">
            Comes with a <span className="font-semibold">3 months free</span>{" "}
            subscription.
          </p>
          <>
            <div className="grid grid-cols-1 gap-4"></div>

            <div className="flex flex-row justify-end mt-6">
              <button
                className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-black focus:ring-4 focus:outline-none bg-white border border-gray-200 rounded-lg focus:ring-background dark:background dark:hover:bg-background dark:focus:ring-background gap-2 justify-center"
                onClick={openShop}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                  />
                </svg>
                Buy a card
              </button>
            </div>
          </>
        </div>
      </div>

      <div className="max-w-sm w-full">
        <div className="flex flex-col gap-4 w-full ">
          <div className="w-full flex flex-col gap-4">
            <button
              className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-black focus:ring-4 focus:outline-none bg-white border border-gray-200 rounded-lg focus:ring-background dark:background dark:hover:bg-background dark:focus:ring-background gap-2 justify-center"
              onClick={() => openUrl("https://pass-card.app/policy")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                />
              </svg>
              Privacy policy
            </button>

            <button
              className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-black focus:ring-4 focus:outline-none bg-white border border-gray-200 rounded-lg focus:ring-background dark:background dark:hover:bg-background dark:focus:ring-background gap-2 justify-center flex-grow"
              onClick={() => openUrl("https://pass-card.app/terms")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                />
              </svg>
              Terms and conditions
            </button>

            <button
              className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-red-500 focus:ring-4 focus:outline-none border-red-500 border bg-transparent rounded-lg focus:ring-background dark:background dark:hover:bg-background dark:focus:ring-background gap-2 justify-center flex-grow"
              onClick={() => setShowConfirm(true)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="h-6 w-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M5.636 5.636a9 9 0 1 0 12.728 0M12 3v9"
                />
              </svg>
              Logout
            </button>
          </div>
        </div>
      </div>

      <Modal
        show={showConfirm}
        size="md"
        popup={true}
        dismissible={true}
        onClose={() => setShowConfirm(false)}
      >
        <Modal.Header>
          <div className="text-sm">Confirm</div>
        </Modal.Header>
        <Modal.Body>
          <p className="">Are you sure you want to logout?</p>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-background border border-transparent rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-background"
            onClick={logout}
          >
            Logout
          </button>
          <button
            className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 border border-transparent rounded-md hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
            onClick={() => setShowConfirm(false)}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export { Account };
