import { appDb } from "config";
import { DocumentReference, Timestamp, doc, getDoc } from "firebase/firestore";
import { IUserInfoForms } from "pages/info/useInfo";
import React, { useEffect, useState } from "react";
import { useAuth } from "services/authServer";
import { Theme, UserContext } from "./userContext";
import Vcard from "vcard-creator";
import { Options } from "pages/account";

interface Props {
  children: React.ReactNode;
}
export interface Sub {
  type: Options;
  date_processed: Timestamp;
  transactionId: string;
}
export const UserProvider = ({ children }: Props): React.ReactElement => {
  const { user } = useAuth();
  const [loadingProfile, setLoadingProfile] = useState<boolean>(false);
  const [loadingSub, setLoadingSub] = useState<boolean>(false);
  const [userIsNew, setUserIsNew] = useState<boolean>(false);
  const [userChecked, setUserChecked] = useState<boolean>(false);
  const [profile, setProfile] = useState<IUserInfoForms | null>(null);
  const [sub, setSub] = useState<Sub | undefined>();
  const [expiry, setExpiry] = useState<Date | undefined>();
  const [isExpired, setIsExpired] = useState<boolean>(true);
  const [profileError, setProfileError] = useState<string | null>(null);
  const [cardInfo, setCardInfo] = useState<string | null>(null);
  const [theme, setTheme] = useState<Theme>({
    primary: "#000000",
    secondary: "#FFFFFF",
    background: "#047857",
    accent: "#10b981",
  });

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--primary-color",
      theme.primary
    );
    document.documentElement.style.setProperty(
      "--secondary-color",
      theme.secondary
    );
    document.documentElement.style.setProperty(
      "--background-color",
      theme.background
    );
    document
      .querySelector('meta[name="theme-color"]')
      ?.setAttribute("content", theme.background);

    document.documentElement.style.setProperty("--accent-color", theme.accent);
  }, [theme]);

  useEffect(() => {
    if (user) {
      getProfile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (user) {
      getSub();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (sub) {
      getExpiry();
      getIsExpired();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sub]);

  useEffect(() => {
    if (profile) {
      // const content = getVcard(profile);
      // setCardInfo(content);
      localStorage.setItem(
        "offlineContent",
        `https://profile.pass-card.app/${user?.uid}`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  const getProfile = (): void => {
    setLoadingProfile(true);
    const docRef = doc(appDb, "public_profiles", user!.uid);
    getDoc(docRef)
      .then((docSnap) => {
        if (docSnap.exists()) {
          const data = docSnap.data();
          setProfile(data as IUserInfoForms);
          const teamDoc = data.team;
          if (teamDoc) {
            getTeam(data.team);
          }
        } else {
          setUserIsNew(true);
        }
        setLoadingProfile(false);
        setUserChecked(true);
      })
      .catch((err) => {
        console.error(err);
        setProfileError(err);
        setLoadingProfile(false);
      });
  };

  const getTeam = (reference: DocumentReference): void => {
    setLoadingProfile(true);
    getDoc(reference)
      .then((docSnap) => {
        if (docSnap.exists()) {
          const { theme: orgTheme, sub, brand } = docSnap.data();
          setTheme({
            accent: orgTheme.accent,
            background: orgTheme.background,
            primary: orgTheme.primary,
            secondary: orgTheme.secondary,
          });
        }
        setLoadingProfile(false);
      })
      .catch((err) => {
        console.error(err);
        setProfileError(err);
        setLoadingProfile(false);
      });
  };

  const getSub = (): void => {
    setLoadingSub(true);
    const docRef = doc(appDb, "subs", user!.uid);
    getDoc(docRef)
      .then((docSnap) => {
        if (docSnap.exists()) {
          setSub(docSnap.data() as Sub);
        }
        setLoadingSub(false);
      })
      .catch((err) => {
        console.error(err);
        setLoadingSub(false);
      });
  };

  const getVcard = (data: IUserInfoForms): string => {
    const vcard = new Vcard();
    vcard.addName(data.general?.full_names);
    vcard.addEmail(data.general?.email || "");
    vcard.addPhoneNumber(data.general?.phone || "");
    vcard.addURL(data.general?.website || "");
    vcard.addCompany(data.general?.company || "");
    vcard.addJobtitle(data.general?.job_title || "");
    vcard.addURL(`https://profile.pass-card.app/${user?.uid}`);
    return vcard.toString();
  };

  const getExpiry = () => {
    if (!sub) return;
    if (sub.type === Options.LIFETIME) {
      let today = new Date();
      setExpiry(
        new Date(new Date(today.setFullYear(today.getFullYear() + 100)))
      );
    }
    const subDate = new Date(sub.date_processed.seconds * 1000);
    const expiry =
      sub.type === Options.MONTHLY
        ? new Date(
            subDate.getFullYear(),
            subDate.getMonth() + 1,
            subDate.getDate()
          )
        : new Date(
            subDate.getFullYear() + 1,
            subDate.getMonth(),
            subDate.getDate()
          );
    setExpiry(expiry);
  };

  const getIsExpired = () => {
    if (!sub) {
      return;
    }
    if (sub.type === Options.LIFETIME) {
      setIsExpired(false);
      return;
    }
    const today = new Date();
    const subDate = new Date(sub.date_processed.seconds * 1000);
    const expiry =
      sub.type === Options.MONTHLY
        ? new Date(
            subDate.getFullYear(),
            subDate.getMonth() + 1,
            subDate.getDate()
          )
        : new Date(
            subDate.getFullYear() + 1,
            subDate.getMonth(),
            subDate.getDate()
          );
    setIsExpired(today > expiry);
  };

  return (
    <UserContext.Provider
      value={{
        loadingProfile,
        userIsNew,
        userChecked,
        profile,
        cardInfo,
        expiry,
        isExpired,
        loadingSub,
        sub,
        profileError,
        theme,
        getProfile,
        setSub,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
