import { SpinnerDotted } from "spinners-react";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "store/user/userContext";
import QrCodeView from "./../../components/qrCodeView";
import { useAuth } from "services/authServer";
import { useNavigate } from "react-router-dom";
import { routes } from "pages/home";
import { Spinner } from "./../../components/loader";
import { appDb } from "config";
import { doc, setDoc } from "firebase/firestore";
import { Sheet } from "react-modal-sheet";
import { Tooltip } from "flowbite-react";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
function BusinessCard() {
  const [published, setPublished] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState(false);
  const { user, loading, loadingUser } = useAuth();
  const { profile, loadingProfile, getProfile } = useContext(UserContext);
  const [codeExpanded, setCodeExpanded] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (profile) {
      setPublished(profile?.private || false);
    }
  }, [profile]);

  const goToInfo = () => {
    navigate(routes.myInfo, { replace: true });
  };

  const getUrl = (): string => {
    return `https://profile.pass-card.app/${user?.uid}`;
  };

  const updateStatus = (state: boolean) => {
    setLoadingStatus(true);
    const ref = doc(appDb, "public_profiles", user!.uid);
    setDoc(ref, { private: state }, { merge: true })
      .then(() => {
        setLoadingStatus(false);
        getProfile();
      })
      .catch((err) => {
        console.error(err);
        setLoadingStatus(false);
      });
  };

  const share = async () => {
    const shareDetails = {
      url: `${getUrl()}`,
      title: "My business card",
      text: "Hello there, here is my business card.",
    };
    if (navigator.share) {
      try {
        await navigator.share(shareDetails);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const preview = () => {
    //window.location.href = getUrl();
    window.open(getUrl(), "_blank");
  };

  if (loading || loadingProfile || loadingUser) {
    return (
      <div className="flex w-screen h-screen">
        <div className="m-auto">
          <SpinnerDotted
            size={50}
            thickness={100}
            speed={100}
            color="#36ad47"
          />
        </div>
      </div>
    );
  }

  const customCard = () => (
    <div className="p-2 justify-center items-center flex flex-col content-center">
      <div className="text-white text-sm mt-16">
        Your custom business card shows here
      </div>
      <button
        type="submit"
        className="text-white text-md rounded-md p-2 mt-8 bg-emerald-500 w-fit"
        onClick={goToInfo}
      >
        Upload custom business card
      </button>
    </div>
  );

  const infoReq = () => (
    <div className="p-2 justify-center items-center flex flex-col mb-8 content-center">
      <div className="text-black text-sm mt-16">
        To generate your business card, please fill in your information
      </div>
      <button
        type="submit"
        className="text-white text-md rounded-md p-2 mt-8 bg-emerald-500 w-fit"
        onClick={goToInfo}
      >
        Add information
      </button>
    </div>
  );

  return (
    <div className="flex justify-center">
      <div className="p-2 grid gap-4 w-full">
        <div className="rounded-md w-full mb-64">
          {/* {profile && (
            <div className="text-gray text-sm text-center mt-4">
              A profile is a live business card, which can be shared with anyone
              online.
            </div>
          )} */}
          <div className="flex justify-center divide-slate-200 gap-4 w-full ">
            {profile && (
              <button className="mb-8" onClick={() => setCodeExpanded(true)}>
                <QrCodeView
                  url={getUrl()}
                  fullSize
                  caption="Scan code for my online business card."
                />
              </button>
            )}
          </div>
          {profile && (
            <>
              <div className="flex justify-center">
                {loadingStatus && <Spinner />}
              </div>
              <div className="flex justify-center items-center gap-2">
                <label className="inline-flex items-center cursor-pointer gap-4">
                  <input
                    type="checkbox"
                    className="sr-only peer accent-current"
                    disabled={loadingStatus}
                    checked={published}
                    onChange={(e) => updateStatus(e.target.checked)}
                  />
                  <div className="relative w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-acent dark:peer-focus:ring-emerald-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-accent"></div>
                  <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                    Make private
                  </span>
                </label>
                <Tooltip
                  content="When private, scanning your business card adds your contacts
                directly to your prospects' contacts list, and will hide your
                online profile"
                  style="light"
                >
                  <button>
                    <InformationCircleIcon className="h-5 w-5" />
                  </button>
                </Tooltip>
              </div>
            </>
          )}
        </div>
      </div>
      <footer className="fixed bottom-20 left-0 right-0 flex items-center justify-between mb-0">
        <div className="w-full flex justify-center bg-white p-2">
          {profile ? (
            <div className="bg-white border rounded-2xl shadow-2xl max-w-sm w-full p-4">
              <div className="flex items-center justify-between">
                <div className="flex-auto">
                  <button
                    type="button"
                    className="text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 m-auto"
                    onClick={preview}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                      />
                    </svg>
                    <span className="ml-1">Preview</span>
                  </button>
                </div>
                <div className="flex-none">
                  <button
                    type="button"
                    className="text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-full text-sm p-3 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 w-12 h-12"
                    onClick={share}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M7.217 10.907a2.25 2.25 0 100 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186l9.566-5.314m-9.566 7.5l9.566 5.314m0 0a2.25 2.25 0 103.935 2.186 2.25 2.25 0 00-3.935-2.186zm0-12.814a2.25 2.25 0 103.933-2.185 2.25 2.25 0 00-3.933 2.185z"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="fixed bottom-32 left-0 right-0 flex md:items-center md:justify-between mb-0">
              <div className="w-full">{infoReq()}</div>
            </div>
          )}
          <Sheet isOpen={codeExpanded} onClose={() => setCodeExpanded(false)}>
            <Sheet.Container>
              <Sheet.Header />
              <Sheet.Content className="flex flex-col items-center">
                <div className="flex justify-center items-center max-w-screen-sm space-y-6">
                  <QrCodeView
                    url={`https://profile.pass-card.app/${user?.uid}`}
                    fullSize
                    caption="Scan code for my online business card."
                  />
                </div>
                <div className="flex justify-center items-center max-w-screen-sm space-y-6 pt-12">
                  <p className="text-sm">
                    Scan code to network with{" "}
                    <span className="font-semibold">
                      {profile?.general?.full_names}
                    </span>
                  </p>
                </div>
                <footer className="fixed bottom-0 left-0 right-0 flex md:items-center md:justify-between mb-0">
                  <div className="w-full">
                    <div className="bg-white w-full p-4 flex justify-center">
                      <span className="text-xs text-gray-500 sm:text-center dark:text-gray-400 ">
                        Powered by{" "}
                        <span className="font-semibold text-sm">
                          <a
                            href="https://digital.pass-card.app"
                            className="hover:underline"
                          >
                            Pass
                          </a>
                        </span>
                      </span>
                    </div>
                  </div>
                </footer>
              </Sheet.Content>
            </Sheet.Container>

            <Sheet.Backdrop />
          </Sheet>
        </div>
      </footer>
    </div>
  );
}

export default BusinessCard;
