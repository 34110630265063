import { Tabs, TextInput, Label } from "flowbite-react";
import { Formik } from "formik";
import { EImageType, IGeneralFormErrors, useInfo } from "./useInfo";
import { Spinner } from "./../../components/loader";
import LinkManager from "./links-manager";
const MyInfo = () => {
  const {
    loading,
    formConfig: { links, general, profile },
    onGeneralFormSubmit,
    onLinksFormSubmit,
    showImagePicker,
    onImageUpload,
  } = useInfo();
  return (
    <div className="flex justify-center">
      <div className="max-w-lg">
        <div className="divide-y bg-white rounded-lg pb-32">
          <div className="block">
            <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white">
              Edit Business card information
            </h5>
            <p className="font-normal text-gray-700 dark:text-gray-400 text-sm ml-4 mb-4">
              The information you add here is used to generate your business
              card, which can be shared with perspective networks.{" "}
              <span className="font-semibold italic">
                Please do not add any information you would not wish to make
                public.
              </span>
            </p>
          </div>
          <Tabs.Group
            aria-label="Tabs with underline"
            className="flex overflow-y-auto accent-current"
          >
            <Tabs.Item title="General" active className="accent-current">
              <Formik
                initialValues={general || {}}
                enableReinitialize
                validate={(values) => {
                  const errors: IGeneralFormErrors = {};
                  if (!values.full_names) {
                    errors.full_names = "Please provide names";
                  }
                  if (!values.email && !values.phone) {
                    errors.email = "Please an email or phone number";
                    errors.phone = "Please a phone number or an email";
                  }
                  return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                  onGeneralFormSubmit(values);
                  setSubmitting(false);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <form
                    onSubmit={handleSubmit}
                    className="flex flex-col gap-4 pl-2 pr-2"
                  >
                    <div>
                      <div className="mb-2 block">
                        <Label htmlFor="names" value="Full names" />
                      </div>
                      <TextInput
                        id="full_names"
                        type="text"
                        placeholder="Enter full names"
                        required={true}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.full_names}
                      />
                      {errors.full_names && (
                        <small className="text-red-700">
                          {errors.full_names}
                        </small>
                      )}
                    </div>
                    <div>
                      <div className="mb-2 block">
                        <Label htmlFor="role" value="Job Title" />
                      </div>
                      <TextInput
                        id="job_title"
                        type="text"
                        placeholder="Enter job title"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.job_title}
                      />
                    </div>
                    <div>
                      <div className="mb-2 block">
                        <Label htmlFor="company" value="Company/Org name" />
                      </div>
                      <TextInput
                        id="company"
                        type="text"
                        placeholder="Enter company name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.company}
                      />
                    </div>
                    <div>
                      <div className="mb-2 block">
                        <Label htmlFor="email" value="Email" />
                      </div>
                      <TextInput
                        id="email"
                        type="email"
                        placeholder="Enter your email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                      />
                      {errors.email && (
                        <small className="text-red-700">{errors.email}</small>
                      )}
                    </div>
                    <div>
                      <div className="mb-2 block">
                        <Label htmlFor="phone" value="Phone number" />
                      </div>
                      <TextInput
                        id="phone"
                        type="phone"
                        placeholder="Enter your phone"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.phone}
                      />
                      {errors.phone && (
                        <small className="text-red-700">{errors.phone}</small>
                      )}
                    </div>
                    <div>
                      <div className="mb-2 block">
                        <Label htmlFor="website" value="Company website" />
                      </div>
                      <TextInput
                        id="website"
                        type="text"
                        placeholder="Enter company website"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.website}
                      />
                    </div>
                    <button
                      type="submit"
                      className="text-secondary text-md rounded-md p-2 mt-4 bg-background"
                      disabled={loading}
                    >
                      {loading ? <Spinner /> : "Save"}
                    </button>
                  </form>
                )}
              </Formik>
              <div className="mt-8 border-t pt-4">
                <h5 className="mb-2 text-lg font-bold tracking-tight text-gray-900 dark:text-white">
                  Profile/Brand picture
                </h5>
                <p className="font-normal text-gray-700 dark:text-gray-400 text-sm">
                  Upload an image you would wish to show on your profile. It
                  could be your brand's logo or your professional headshot.
                </p>
                <div className="flex justify-center items-center mt-6">
                  <button
                    className="rounded-full h-48 w-48 border border-gray-300 border-dashed flex justify-center items-center hover:border-accent shadow-md hover:shadow-none"
                    onClick={() => showImagePicker(EImageType.Profile)}
                    disabled={loading}
                  >
                    {!profile && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="gray"
                        className="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
                        />
                      </svg>
                    )}
                    {profile && (
                      <img
                        src={profile}
                        alt="profile"
                        className="rounded-full h-48 w-48 object-cover"
                      />
                    )}
                  </button>
                  <input
                    id={EImageType.Profile}
                    type="file"
                    accept="image/png, image/jpeg"
                    aria-label="profile-upload"
                    className="opacity-0 hidden"
                    onChange={(e) =>
                      onImageUpload(EImageType.Profile, e.target.files)
                    }
                  />
                </div>
              </div>
            </Tabs.Item>
            <Tabs.Item title="Links">
              <LinkManager
                loading={loading}
                onCommit={onLinksFormSubmit}
                initialLinks={links}
              />
            </Tabs.Item>
            {/* <Tabs.Item title="Custom Business Card">
              <div className="">
                <p className="text-sm">
                  Your digital business card will be generated from your general
                  info. However, you can add a custom business card (See below)
                </p>
                <h5 className="mb-2 mt-4 text-lg font-bold tracking-tight text-gray-900 dark:text-white">
                  Custom business card
                </h5>
                <p className="font-normal text-gray-700 dark:text-gray-400 text-sm">
                  To use a custom, please upload a copy of your business card
                  here, to be shared when someone scans your code.
                </p>
                <div className="grid grid-cols-1 gap-4">
                  <div className="mt-4">
                    <button
                      className="rounded-lg h-48 w-full border border-gray-300 border-dashed flex justify-center items-center shadow-md hover:border-emerald-500 hover:shadow-none"
                      disabled={loading}
                      onClick={() => showImagePicker(EImageType.CardFront)}
                    >
                      {!formConfig.business?.card_front && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="gray"
                          className="w-6 h-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
                          />
                        </svg>
                      )}
                      {formConfig.business?.card_front && (
                        <img
                          src={formConfig.business.card_front}
                          alt="card back"
                          className="rounded-lg h-48 w-full"
                        />
                      )}
                    </button>
                    <input
                      id={EImageType.CardFront}
                      type="file"
                      accept="image/png, image/jpeg"
                      aria-label="profile-upload"
                      className="opacity-0 hidden"
                      onChange={(e) => {
                        onImageUpload(EImageType.CardFront, e.target.files);
                      }}
                    />
                    <p className="text-sm text-gray-500 text-center mt-2">
                      Card's front image
                    </p>
                  </div>
                  <div>
                    <button
                      className="rounded-lg h-48 w-full border border-gray-300 border-dashed flex justify-center items-center shadow-md hover:border-emerald-500 hover:shadow-none"
                      disabled={loading}
                      onClick={() => showImagePicker(EImageType.CardBack)}
                    >
                      {!formConfig.business?.card_back && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="gray"
                          className="w-6 h-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
                          />
                        </svg>
                      )}
                      {formConfig.business?.card_back && (
                        <img
                          src={formConfig.business.card_back}
                          alt="card back"
                          className="rounded-lg h-48 w-full"
                        />
                      )}
                    </button>
                    <input
                      id={EImageType.CardBack}
                      type="file"
                      accept="image/png, image/jpeg"
                      aria-label="profile-upload"
                      className="opacity-0 hidden"
                      onChange={(e) =>
                        onImageUpload(EImageType.CardBack, e.target.files)
                      }
                    />
                    <p className="text-sm text-gray-500 text-center mt-2">
                      Card's back image (Optional)
                    </p>
                  </div>
                </div>
              </div>
            </Tabs.Item> */}

            {/* <Tabs.Item title="Office">
          <Formik
            initialValues={formConfig.office || {}}
            enableReinitialize
            validate={(values) => {
              const errors: IOfficeFormErrors = {};
              if (!values.employee_id) {
                errors.employee_id = "Please provide employee ID";
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              onOfficeFormSubmit(values);
              setSubmitting(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                <div>
                  <div className="mb-2 block">
                    <Label htmlFor="officeID" value="Employee ID" />
                  </div>
                  <TextInput
                    id="employee_id"
                    type="text"
                    placeholder="Enter employee/office ID"
                    required={true}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.employee_id}
                  />
                  {errors.employee_id && (
                    <small className="text-red-700">{errors.employee_id}</small>
                  )}
                </div>
                <button
                  type="submit"
                  className="text-white text-md rounded-md p-2 mt-4 bg-emerald-500"
                  disabled={loading}
                >
                  {loading ? <Spinner /> : "Save"}
                </button>
              </form>
            )}
          </Formik>
        </Tabs.Item>
        <Tabs.Item title="School">
          <Formik
            initialValues={formConfig.school || {}}
            enableReinitialize
            validate={(values) => {
              const errors: ISchoolFormErrors = {};
              if (!values.student_id) {
                errors.student_id = "Please provide student ID";
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              onSchoolFormSubmit(values);
              setSubmitting(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                <div>
                  <div className="mb-2 block">
                    <Label htmlFor="studentID" value="Student ID" />
                  </div>
                  <TextInput
                    id="student_id"
                    type="text"
                    placeholder="Enter student ID"
                    required={true}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.student_id}
                  />
                  {errors.student_id && (
                    <small className="text-red-700">{errors.student_id}</small>
                  )}
                </div>
                <button
                  type="submit"
                  className="text-white text-md rounded-md p-2 mt-4 bg-emerald-500"
                  disabled={loading}
                >
                  {loading ? <Spinner /> : "Save"}
                </button>
              </form>
            )}
          </Formik>
        </Tabs.Item> */}
          </Tabs.Group>
        </div>
      </div>
    </div>
  );
};

export { MyInfo };
